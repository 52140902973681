import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo.png"; // Make sure to adjust the path to your logo

const AccountActivated = () => {
  const navigate = useNavigate();

  // Redirect after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://us.rs-online.com/';
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [navigate]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow-md w-full">
        <div className="container mx-auto flex flex-wrap items-center justify-between p-4 space-y-4 sm:space-y-0">
          {/* RS Logo and Search */}
          <div className="flex items-center space-x-2 w-full sm:w-auto">
            <img
              src={logo}
              alt="RS Logo"
              className="h-10"
            />
            <input
              type="text"
              placeholder="Part Number/Keywords"
              className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 w-full sm:w-auto"
            />
          </div>

          {/* Navigation Links */}
          <nav className="flex flex-wrap items-center space-x-4 sm:space-x-6 w-full sm:w-auto justify-center">
            <a href="#" className="text-gray-700 hover:underline block sm:inline-block">All Products</a>
            <a href="#" className="text-gray-700 hover:underline block sm:inline-block">Manufacturers</a>
            <a href="#" className="text-gray-700 hover:underline block sm:inline-block">Quick Order</a>
            <a href="#" className="text-gray-700 hover:underline block sm:inline-block">Orders & Quotes</a>
          </nav>

          {/* User Account & Cart */}
          <div className="flex items-center space-x-4 sm:space-x-6 w-full sm:w-auto justify-end">
            <div className="text-gray-700">USA - ENG</div>
            <a href="#" className="text-gray-700 hover:underline">Login</a>
            <a href="#" className="text-gray-700 hover:underline">Register</a>
            <div className="flex items-center">
              <img
                src="https://img.icons8.com/material-rounded/24/000000/shopping-cart.png"
                alt="Cart"
                className="h-6 w-6"
              />
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center flex-grow px-4">
        <div className="w-full max-w-md bg-white shadow-md rounded-lg p-8 text-center">
          <h1 className="text-2xl font-semibold text-gray-700 mb-4">Thank you!</h1>
          <p className="text-gray-600">Your account has been activated.</p>
          <p className="text-gray-500 mt-2">You will be redirected shortly...</p>
        </div>
      </div>
    </div>
  );
};

export default AccountActivated;
